
import avaSrc from './ava.png';

import styles from './ReviewSection.module.scss';



const reviews = [
    {
        rttfId: 106945,
        author: 'Пимашкин Максим',
        text: 'Просто, спокойно и доходчиво объясняет. Даёт подводящие упражнения от простого к сложному, прекрасно видит ошибки и их поправляет. Приятная и доброжелательная атмосфера, ощущения будто занимаешься со старым другом. Тренировкой доволен, всем рекомендую. Большое спасибо, Павел!',
    },
    {
        rttfId: 50784,
        author: 'Агабабян Роберт',
        text: 'Павел очень открытый и доброжелательный тренер. Ну и мастерство на высочайшем уровне, многие моменты сходу видит и объясняет как делать правильно. За 5 минут научил меня делать то, что нестабильно получалось до этого.\n' +
            '\n' +
            'Рекомендую этого тренера всем как начинающим так и уже опытным игрокам!',
    },
    {
        rttfId: 92940,
        author: 'Даневич Сергей',
        text: 'Опять выиграл тренировку на рттф и конечно же опять к Павлу.Тренировка прошла классно,человек доступно и понятно отвечает на вопросы,объясняет недочёты,ну и сама тренировка прошла интересно.Как тренер Павел молодец,всё ясно,понятно и без понтов.Однозначно рекомендую.',
    },
    {
        rttfId: 4163,
        author: 'Овчинников Владимир',
        text: 'Тренировался с Павлом. Если у кого есть сомнения в выборе тренера - Павел будет ваш лучший выбор',
    },
    {
        rttfId: 50220,
        author: 'Немуров Евгений',
        text: 'Тренировкой остался доволен, планирую в дальнейшем продолжить занятия.  Опытный тренер с хорошей энергетикой. Рекомендую',
    },
]

const ReviewSection = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}><h1>Отзывы</h1></div>
            <div className={styles.reviewContainer}>
                {reviews.map((item, key) => (
                    <div key={key} className={styles.review}>
                        <div className={styles.review__ava}>
                            <object data={`https://rttf.ru/public/img/players/${item.rttfId}/avatar.jpg`} type="image/png">
                                <img src={avaSrc} alt="Stack Overflow logo and icons and such" />
                            </object>
                        </div>
                        <div style={{display: "flex", flexDirection: 'column', gap: 10}}>
                            <div className={styles.review__name}>{item.author}</div>
                            <div className={styles.review__text}>{item.text}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div style={{
                fontSize: 20,
                textDecoration: 'none',
                paddingTop: 25,
                textAlign: 'center'
            }}>
                <a href="https://rttf.ru/coaches/55851" rel="noopener noreferrer" target="_blank" >Читать больше отзывов</a>
            </div>
        </div>
    );
}

export default ReviewSection;
