import styles from './MapSection.module.scss';

import lucenter from './lucenter.png';
import huricane from './huricane.png';
import fili from './fili.png';

const areas = [
    {
        name: 'Клуб «ЛУ.Центр»',
        address: 'Старокирочный пер., д 2, Москва',
        ya: 'https://yandex.ru/maps/org/lutsentr/101699636488/?ll=37.680490%2C55.768522&z=15',
        img: lucenter
    },
    {
        name: 'Hurricane-TT, клуб настольного тенниса',
        address: 'M Савёловская, улица Сущёвский Вал, 5с11',
        ya: 'https://yandex.ru/maps/org/hurricane_tt/223855535182/?ll=37.593980%2C55.796675&z=15',
        img: huricane
    }
    ,{
        name: 'Фили (Филион) Wings Club',
        address: 'M Фили, Багратионовский пр., 5, Москва (этаж 4)',
        ya: 'https://yandex.ru/profile/215186853574',
        img: fili
    }
];

const MapSection = () => (
    <div className={styles.wrapper}>
        <h1>Где проходят тренировки</h1>
        <div className={styles.wrapperItems}>
            {areas.map((item, index) => (
                <div key={index} className={styles.item}>
                    <div className={styles.itemPic} style={{
                        backgroundImage: `url(${item.img})`
                    }}></div>
                    <div>
                        <div className={styles.title}>{item.name}</div>
                        {item.address}<br/>
                        <a rel="noreferrer" target="_blank" href={item.ya}>Открыть в Яндекс Картах</a>
                    </div>
                </div>
            ))}
            <div className={styles.item}>
                <div>
                    <div className={styles.title}>Тренировка с выездом</div>
                    Возможно проведение занятия в офисе и на дому в г. Москве и Московской области
                </div>
            </div>
        </div>
    </div>
);

export default MapSection;