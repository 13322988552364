import styles from './PriceCard.module.scss';
import PHONE from "../../PHONE";

const defaultDesc = [
    'Индивидуальный подход',
    'Постановка техники',
    'Подбор инвентаря',
    'Выявление ошибок',
    'Наставления на игру'
];

// @ts-ignore
const PriceCard = ({title, price, desc = defaultDesc, subTitle}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {title}
                <div className={styles.subTitle}>
                    {subTitle}
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.price}>
                    {price}
                </div>
                <div className={styles.desc}>
                    {desc.map((item, index) => (
                        <div key={index} className={styles.descItem}>{item}</div>
                    ))}
                </div>
                <a className={styles.button} href={`https://api.whatsapp.com/send?phone=${PHONE}&text=${encodeURI(`Здравствуйте. Хочу записаться на тренировку по настольному теннису."${title}"`)}`}>
                    Записаться
                </a>
            </div>
        </div>
    );
};

export default PriceCard;