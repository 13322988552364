import styles from './GallerySection.module.scss';
import photo1 from './small_photo.jpg';

const AboutSection = () => (
    <div className={`${styles.wrapper} ${styles.wrapper_base}`}>
        <div className={`${styles.block} ${styles.block_image}`}>
            <img src={photo1} alt="Тренировки по настольному теннису в Москве" loading="lazy"/>
        </div>
        <div className={`${styles.wrapper} ${styles.wrapper_column}`}>
            <div className={styles.title}><h1>О тренере</h1></div>
            <div className={`${styles.wrapper} ${styles.wrapper50}`}>
                <div className={styles.block}>
                    Мастер спорта по настольному теннису. <br/>
                    Призер чемпионата России и международных турниров.
                    Тренер высшей категории со стажем более 20 лет
                </div>
                <div className={styles.block}>
                    За многолетний период тренерской работы удалось воспитать множество КМС, несколько мастеров спорта,
                    а также победительницу первенства России, которая в настоящее время является членом сборной России.
                </div>
            </div>
        </div>
    </div>
);

export default AboutSection;