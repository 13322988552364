import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const element = document.getElementById('root') as HTMLElement;
if (element.hasChildNodes()) {
    ReactDOM.hydrateRoot(
        element,
        <App />
    )
} else {
    const root = ReactDOM.createRoot(element);

    root.render(
        <App />
    );
}
