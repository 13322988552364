import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PriceCard from "./PriceCard";

import styles from './PriceSection.module.scss';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const prices = [
    {
        title: 'Пробная',
        subTitle: 'тренировка',
        price: '2 000 / час',
    },
    {
        title: 'Индивидуальная',
        subTitle: 'тренировка',
        price: '4 000 / час',
    },
    {
        title: 'Индивидуальная',
        subTitle: 'для мужчин 60+ и девушек 50+',
        price: '3 000 / час',
    },
    {
        title: 'Групповая',
        subTitle: 'тренировка (от 2 человек)',
        price: '5 000 / час',
    },
    {
        title: 'Групповая детская',
        subTitle: 'тренировка (от 2 человек)',
        price: '4 000 / 1 час',
    },
    {
        title: 'С выездом',
        subTitle: 'Выезд по МСК и МО',
        price: '10 000 / 2 часа',
    },
    {
        title: 'Детская',
        subTitle: 'с 6 до 18 лет и студенты',
        price: '3 000 / час!',
    }
];

const PriceSection = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}><h1>Стоимость услуг</h1></div>
            <Carousel responsive={responsive}>
                {prices.map(({subTitle, title, price}, index) => (
                    <div key={index}><PriceCard title={title} subTitle={subTitle} desc={undefined} price={price} /></div>
                ))}
            </Carousel>
        </div>
    );
}

export default PriceSection;
