import styles from './VideoSection.module.scss';

const matches = [
    {
        name: 'Igor Rubtsov - Pavel Perov. Russian Men\'s Super League',
        youtubeId: 'eYbTaYjFqPs',
    },
    {
        name: 'Андрей БАЙБУЛДИН vs Павел ПЕРОВ, Чемпионат России',
        youtubeId: 'QfnpByLYVsM',
    },
    {
        name: 'П. Корбел (Чехия) - П. Перов (Ярославль)',
        youtubeId: '8_3ndLMrCEE',
    },
    {
        name: 'Подборка красивых розыгрышей с моим участием в LIGA PRO',
        cover: `https://i3.ytimg.com/vi/1BrlL4DEwHE/hqdefault.jpg`,
        url: 'https://www.youtube.com/playlist?list=PLidcw7lHiw0w3Dpb_0U4Swf6dYPVZy1f1'
    },
];

const videos: {
    name: string,
    youtubeId: string
    cover?:string
    url?: string
}[] = [
    {
        name: '3 Принципа Кручёной Подачи',
        youtubeId: 'wuFdkrq3Znk',
    },
    {
        name: 'КАСАНИЕ Топс Справа (контакт ракетки с мячом)',
        youtubeId: 'mbylCAjzwjo',
    },
    {
        name: 'МЕХАНИКА Топс Слева (работа частей тела)',
        youtubeId: 'PUZsmVhjMmk',
    },
    {
        name: 'Лови ПРОТИВОХОД - Обратка СЛЕВА',
        youtubeId: 'BtTWVI7u_FA',
    },
    {
        name: 'Накат Справа делает ученица',
        youtubeId: 'eb2nW1q93sI',
    },
    {
        name: 'Идеальная подрезка',
        youtubeId: 'G8VOb7EwN18',
    },
];

const VideoSection = () => (
    <>
        <div className={styles.wrapper}>
            <h1>Видео с матчей</h1>
            <div className={styles.itemWrapper}>
                {matches.map((item, index) => (
                    <a key={index} rel="noreferrer" className={styles.item} target="_blank" href={!item.url ? `https://www.youtube.com/watch?v=${item.youtubeId}` : item.url}>
                        <div className={styles.itemCover} style={{
                            backgroundImage: !item.cover ? `url(https://i3.ytimg.com/vi/${item.youtubeId}/hqdefault.jpg)` : `url(${item.cover})`
                        }}>
                            <div className={styles.itemTitle}>
                                {item.name}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
        <div className={styles.wrapper}>
            <h1>Обучающие видео</h1>
            <div className={styles.itemWrapper}>
                {videos.map((item, index) => (
                    <a key={index} rel="noreferrer" className={styles.item} target="_blank" href={item.youtubeId ? `https://www.youtube.com/watch?v=${item.youtubeId}` : (item.url as string)}>
                        <div className={styles.itemCover} style={{
                            backgroundImage: item && !('cover' in item) ? `url(https://i3.ytimg.com/vi/${item.youtubeId}/hqdefault.jpg)` : `url(${item.cover})`
                        }}>
                            <div className={styles.itemTitle}>
                                {item.name}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            <div style={{
                textAlign: "center",
                padding: "27px 10px"
            }}>
                Больше видео на моём <a href="https://www.youtube.com/@TTPavelPerov78" rel="noreferrer" target="_blank">youtube</a> канале
            </div>
        </div>
    </>
);

export default VideoSection;